import request from '@/utils/request';
export default {
  queryHangdao: function queryHangdao() {
    return request.get("/app-doc-center/v1/gml/directory/project").then(function (res) {
      return res;
    });
  },
  queryRootId: function queryRootId(spaceId) {
    return request.get("/app-doc-center/v1/gml/directory/root?spaceId=".concat(spaceId)).then(function (res) {
      return res;
    });
  },
  queryTree: function queryTree(folderId) {
    return request.get("/app-doc-center/v1/gml/directory/list?folderId=".concat(folderId)).then(function (res) {
      return res;
    });
  },
  queryNodeRole: function queryNodeRole(data) {
    return request.post("/app-doc-center/v1/gml/role/query", data).then(function (res) {
      return res;
    });
  },
  queryAllRole: function queryAllRole() {
    var roleName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return request.get("/app-doc-center/v1/gml/role/list?roleName=".concat(roleName)).then(function (res) {
      return res;
    });
  },
  addRole: function addRole(data) {
    return request.post("/app-doc-center/v1/gml/role/add", data).then(function (res) {
      return res;
    });
  },
  delRole: function delRole(id) {
    return request.post("/app-doc-center/v1/gml/role/delete?id=".concat(id)).then(function (res) {
      return res;
    });
  }
};