var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "jurisdictionSet" } }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("div", { staticClass: "left" }, [
          _c(
            "div",
            { staticClass: "top" },
            [
              _c("span", [_vm._v("航道: ")]),
              _c(
                "el-select",
                {
                  staticClass: "el-select--maia",
                  attrs: {
                    "value-key": "id",
                    placeholder: "请选择航道",
                    size: "medium"
                  },
                  on: { change: _vm.hangdaoChange },
                  model: {
                    value: _vm.hangdaoValue,
                    callback: function($$v) {
                      _vm.hangdaoValue = $$v
                    },
                    expression: "hangdaoValue"
                  }
                },
                _vm._l(_vm.hangdaoOptions, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.templateName, value: item }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom" },
            [
              _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  _vm.argument.folderId
                    ? _c("el-tree", {
                        ref: "tree",
                        attrs: {
                          props: _vm.defaultProps,
                          "default-expanded-keys": _vm.unfoldIdArr,
                          load: _vm.loadNode,
                          accordion: "",
                          "highlight-current": "",
                          "node-key": "folderId",
                          lazy: ""
                        },
                        on: {
                          "node-collapse": _vm.clickNodeTree,
                          "node-expand": _vm.clickNodeTree,
                          "node-click": _vm.clickNodeTree
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var node = ref.node
                                var data = ref.data
                                return _c(
                                  "div",
                                  { staticClass: "custom-tree-node" },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("./image/folder.png"),
                                        alt: "文件夹"
                                      }
                                    }),
                                    _c("div", { attrs: { title: data.name } }, [
                                      _vm._v(" " + _vm._s(data.name))
                                    ])
                                  ]
                                )
                              }
                            }
                          ],
                          null,
                          false,
                          2290578592
                        )
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "right" }, [
          _c(
            "div",
            { staticClass: "top" },
            [
              _c("el-input", {
                staticStyle: { width: "200px", "margin-right": "10px" },
                attrs: {
                  placeholder: "请输入内容",
                  clearable: "",
                  size: "medium"
                },
                model: {
                  value: _vm.listSearchValue,
                  callback: function($$v) {
                    _vm.listSearchValue = $$v
                  },
                  expression: "listSearchValue"
                }
              }),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: _vm.templateIds.some(function(item) {
                      return item.templateId === _vm.argument.folderId
                    }),
                    type: "maiaPrimary",
                    size: "medium"
                  },
                  on: { click: _vm.openRoleBox }
                },
                [_vm._v("添加")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom" },
            [
              _c(
                "el-table",
                {
                  staticClass: "is-grey",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.data }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "roleName", label: "项目角色", width: "300" }
                  }),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "name",
                        label: "文件夹可见",
                        width: "300",
                        align: "center"
                      }
                    },
                    [
                      _c("img", {
                        attrs: { src: require("./image/ok.png"), alt: "可见" }
                      })
                    ]
                  ),
                  _c("el-table-column", {
                    attrs: { prop: "address", label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c("img", {
                              staticStyle: { cursor: "pointer" },
                              attrs: {
                                src: require("./image/del.png"),
                                alt: "删除"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.delRole(row)
                                }
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ]),
        _c(
          "el-dialog",
          {
            staticClass: "el-dialog--maia",
            attrs: { title: "添加", visible: _vm.dialogVisible1, width: "35%" },
            on: {
              "update:visible": function($event) {
                _vm.dialogVisible1 = $event
              },
              close: _vm.closeRoleBox
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "warp",
                staticStyle: { padding: "20px", "box-sizing": "border-box" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "top",
                    staticStyle: { "margin-bottom": "10px" }
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "请输入内容",
                        clearable: "",
                        size: "medium"
                      },
                      model: {
                        value: _vm.roleSearchValue,
                        callback: function($$v) {
                          _vm.roleSearchValue = $$v
                        },
                        expression: "roleSearchValue"
                      }
                    }),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "10px" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "maiaPrimary", size: "mini" },
                            on: { click: _vm.selectAll }
                          },
                          [_vm._v("全选")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "maiaPrimary", size: "mini" },
                            on: { click: _vm.selectNo }
                          },
                          [_vm._v("反选")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "bottom",
                    staticStyle: {
                      "max-height": "300px",
                      "overflow-y": "auto",
                      "background-color": "#f7f9ff",
                      border: "1px solid #D4D9EC",
                      padding: "10px",
                      "box-sizing": "border-box"
                    }
                  },
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        model: {
                          value: _vm.selectRoles,
                          callback: function($$v) {
                            _vm.selectRoles = $$v
                          },
                          expression: "selectRoles"
                        }
                      },
                      _vm._l(_vm.allRole, function(item, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            _c(
                              "el-checkbox",
                              { attrs: { label: item.roleID } },
                              [_vm._v(" " + _vm._s(item.roleName) + " ")]
                            )
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer"
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "maiaPlain", size: "small" },
                    on: { click: _vm.closeRoleBox }
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.btnLoading,
                        expression: "btnLoading"
                      }
                    ],
                    attrs: { type: "maiaPrimary", size: "small" },
                    on: { click: _vm.addRole }
                  },
                  [_vm._v("确 定")]
                )
              ],
              1
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("权限设置")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }